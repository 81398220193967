<template>
  <div>
    <div class="card shadow">
      <div class="pt-4 pb-4 pe-4 ps-4" @keyup.enter="$emit('getRaasList')">
        <div class="row pb-4">
          <div class="col-sm-4 filter">
            <div class="dp__input_wrap text-gray-600">
              <input
                name="Denominazione"
                type="text"
                placeholder="Denominazione"
                class="form-control"
                :value="denominazioneConsiglioRaas"
                @input="setDenominazioneConsiglioRaas($event.target.value)"
              />
            </div>
          </div>
          <div class="col-sm-4 filter">
            <div class="dp__input_wrap text-gray-600">
              <input
                name="cod_fiscale"
                type="text"
                placeholder="Codice fiscale"
                class="form-control"
                :value="codiceFiscConsiglioRaas"
                @input="setCodiceFiscConsiglioRaas($event.target.value)"
              />
            </div>
          </div>
          <div class="col-sm-4 filter">
            <div class="dp__input_wrap text-gray-600">
              <input
                name="cod_affiliazione"
                type="text"
                placeholder="Codice affiliazione"
                class="form-control"
                :value="codiceAffConsiglioRaas"
                @input="setCodiceAffConsiglioRaas($event.target.value)"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3 filter">
            <SelectInput
              placeholder="Comitato"
              :options="comitati"
              name="id_comitato"
              :value="comitatoConsiglioRaas"
              @changeSelect="setComitatoConsiglioRaas"
            />
          </div>
          <div class="col-sm-2 align-self-center text-center">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="stati_invio && stati_invio.includes('1')"
              @click="setDaInviareConsiglioRaas()"
            />
            <label class="fw-bold ps-1 text-gray-800"> Da inviare</label>
          </div>
          <div class="col-sm-2 align-self-center text-center">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="stati_invio && stati_invio.includes('2')"
              @click="setInviateConsiglioRaas()"
            />
            <label class="fw-bold ps-1 text-gray-800"> Corr. Inviata</label>
          </div>
          <div class="col-sm-2 align-self-center text-center">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="stati_invio && stati_invio.includes('3')"
              @click="setErroriConsiglioRaas()"
            />
            <label class="fw-bold ps-1 text-gray-800"> Errori su invio </label>
          </div>
          <div class="col-sm-2 align-self-center text-center">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="stati_invio && stati_invio.includes('4')"
              @click="setDaReinviareConsiglioRaas()"
            />
            <label class="fw-bold ps-1 text-gray-800"> Da reinviare</label>
          </div>
        </div>
        <div class="text-center mt-5">
          <button
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600"
            @click="$emit('getRaasList')"
            :disabled="!loaded"
          >
            Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
          </button>
          <span
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click.prevent="$emit('resetFilters')"
          >
            <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue3-date-time-picker/dist/main.css";
import { defineComponent, computed, ref, watch, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

export default defineComponent({
  name: "filtri-affiliazioni-raas",
  emits: ["resetFilters", "getRaasList"],
  components: {
    SelectInput,
  },

  setup(props, { emit }) {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const comitati = computed(() => store.getters.getStateFromName("comitati"));

    const keys = ref("c");
    if (!comitati.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const stagioneAffiliazioneRaas = computed(
      () => store.getters.stagioneSelected
    );
    watch(stagioneAffiliazioneRaas, () => {
      emit("getRaasList");
    });

    const denominazioneConsiglioRaas = computed(
      () => store.getters.denominazione_raas_consiglio
    );
    const setDenominazioneConsiglioRaas = (event) => {
      store.commit("setDenominazione_raas_consiglio", event);
    };
    const codiceFiscConsiglioRaas = computed(
      () => store.getters.codice_fiscale_raas_consiglio
    );
    const setCodiceFiscConsiglioRaas = (event) => {
      store.commit("setCodice_fiscale_raas_consiglio", event);
    };
    const codiceAffConsiglioRaas = computed(
      () => store.getters.codice_affiliazione_raas_consiglio
    );
    const setCodiceAffConsiglioRaas = (event) => {
      store.commit("setCodice_affiliazione_raas_consiglio", event);
    };
    const comitatoConsiglioRaas = computed(
      () => store.getters.id_comitato_raas_consiglio
    );
    const setComitatoConsiglioRaas = (event) => {
      store.commit("setId_comitato_raas_consiglio", event);
    };
    const daInviareConsiglioRaas = computed(
      () => store.getters.stati_invio_raas_consiglio
    );
    const setDaInviareConsiglioRaas = () => {
      store.dispatch("setDaInviareConsiglioRaas");
    };
    const stati_invio = computed(
      () => store.getters.stati_invio_raas_consiglio
    );
    const setInviateConsiglioRaas = () => {
      store.dispatch("setInviateConsiglioRaas");
    };
    // const erroriConsiglioRaas = computed(
    //   () => store.getters.erroriConsiglioRaas
    // );
    const setErroriConsiglioRaas = () => {
      store.dispatch("setErroriConsiglioRaas");
    };
    // const daReinviareConsiglioRaas = computed(
    //   () => store.getters.daReinviareConsiglioRaas
    // );
    const setDaReinviareConsiglioRaas = () => {
      store.dispatch("setDaReinviareConsiglioRaas");
    };

    return {
      comitati,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedraas_consiglio_list")
      ),
      denominazioneConsiglioRaas,
      codiceFiscConsiglioRaas,
      codiceAffConsiglioRaas,
      comitatoConsiglioRaas,
      daInviareConsiglioRaas,
      stati_invio,
      // inviateConsiglioRaas,
      // erroriConsiglioRaas,
      // daReinviareConsiglioRaas,
      setDenominazioneConsiglioRaas,
      setCodiceFiscConsiglioRaas,
      setCodiceAffConsiglioRaas,
      setComitatoConsiglioRaas,
      setDaInviareConsiglioRaas,
      setInviateConsiglioRaas,
      setErroriConsiglioRaas,
      setDaReinviareConsiglioRaas,
    };
  },
});
</script>

<style></style>
